
<template>
  <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
    <div>
      <b-card title="Nombre de vues par produit">
         


        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
           
        Pour la période :
      

        <b-form-input
        type="text"
        id="datepicker-trigger"
        placeholder="Select dates"
        :value="formatDates(dateOne, dateTwo)"
      />

      <AirbnbStyleDatepicker
        :trigger-element-id="'datepicker-trigger'"
        :mode="'range'"
        :fullscreen-mobile="true"
        :date-one="dateOne"
        :date-two="dateTwo"
        @date-one-selected="val => { dateOne = val }"
        @date-two-selected="val => { dateTwo = val }"
      />
          </b-col>

        </b-row>
       

        <vue-good-table
        v-if="!loading"
          :columns="columns"
          :rows="glasses"
          :pagination-options="{
            enabled: false,
           
          }"
        >
        
          <template 
            slot="table-row"
            slot-scope="props"
            
          >
            
            <span>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        
        </vue-good-table>
        
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          

          </b-col>
          
     

        </b-row>
  
      </b-card>

     
  
    </div>
  </b-overlay>
  </template>
  
  <script>
  import { BButton,BFormInput,BFormDatepicker,BOverlay, BRow, BCol,BCardText,BFormGroup,BFormFile, BMedia,BCard, BFormCheckbox, BFormTextarea} from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import vSelect from 'vue-select'
  import {APIRequest,Auth} from '@simb2s/senseye-sdk'
  import { VueGoodTable } from 'vue-good-table'
import format from 'date-fns/format'
  export default {
    name: 'Profile',
    components: {
      BButton,
      BMedia,
      VueGoodTable,
      BFormInput,
      BCardText,
      BFormGroup,
      BRow,
      BCol,
      BFormFile,
      BOverlay,
      BFormDatepicker,
      BCard,
      BFormCheckbox,
      BFormTextarea,
      vSelect
    },
    data() {
        return {
            edition:false,
            loading:true,
            date_begin:null, 
            dateFormatAPI: 'YYYY-MM-DD',
            dateFormat: 'D MMM YYYY',
            dateOne: this.getDateInit(),
            dateTwo: new Date() ,
            date_end:new Date(),
            stats:0,
            glasses:null,
            columns: [
              {
                label: 'Nom',
                field: 'name',
              },
              {
                label: 'Nombre de vues',
                field: 'views',
              }
             
            ],
            
        }
    },
    
    methods:{
      getDateInit()
      {
        let date = new Date()
        date.setDate(date.getDate() - 30) 
        return date
      },

      formatDates(dateOne, dateTwo) {
        let formattedDates = ''
        if (dateOne) {
          formattedDates = format(dateOne, this.dateFormat)
        }
        if (dateTwo) {
          formattedDates += ' - ' + format(dateTwo, this.dateFormat)
        }

        
        return formattedDates
      },
      editPhoto() {
        this.file=null
      },
      async getStats()
      {
        this.loading=true
        let datebegin=format(this.dateOne, this.dateFormatAPI)
        let dateend=format(this.dateTwo, this.dateFormatAPI)
        console.log('dates new')
        console.log(datebegin)
        for(let i=0;i<this.glasses.length;i++)
        {
          let stats=await APIRequest.get_stats(this.glasses[i].id,datebegin,dateend)
          this.glasses[i].views=stats.data
        }
        this.loading=false

      },
     
   
    },
    watch:{
      dateOne:function(){
        this.getStats()
      },
      dateTwo:function(){
        this.getStats()
      }
    },
  
    
      
    async mounted(){

      // let stats=await APIRequest.get_stats(22)
      // this.stats=stats.data
      // console.log(stats)


      let scans=await APIRequest.list_with_request('glasses', '?creator_owner='+localStorage.id_user_senseye_auth)
      //let scans=await APIRequest.list('glasses')
      this.glasses=scans[0]

      for(let i=0;i<this.glasses.length;i++)
      {
        let stats=await APIRequest.get_stats(this.glasses[i].id,this.dateOne,this.dateTwo)
        this.glasses[i].views=stats.data
      }

      this.loading=false
    }
  
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>